import { PropsWithChildren } from 'react';

import { Content } from './StoriesGridRoot.styles';

export function StoriesGridRoot({ children }: PropsWithChildren<unknown>) {
  if (!children) {
    return null;
  }

  return <Content>{children}</Content>;
}
