import { styled } from '@linaria/react';

const columnDesktop = 'calc(33.33% - var(--spacing-lg) * 2 / 3)';
const columnDesktopSmall = 'calc(50% - var(--spacing-lg) / 2)';
const columnTablet = 'calc(33.33% - var(--spacing-sm) * 2 / 3)';
const columnMobile = 'calc(50% - var(--spacing-sm) / 2)';
export const Content = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${columnDesktop} ${columnDesktop} ${columnDesktop};
  grid-gap: var(--spacing-lg);

  @media only screen and (max-width: 940px) and (min-width: 769px) {
    grid-template-columns: ${columnDesktopSmall} ${columnDesktopSmall};
  }

  @media only screen and (max-width: 768px) and (min-width: 479px) {
    grid-template-columns: ${columnTablet} ${columnTablet} ${columnTablet};
    grid-gap: var(--spacing-sm);
  }

  @media only screen and (max-width: 478px) {
    grid-template-columns: ${columnMobile} ${columnMobile};
    grid-gap: var(--spacing-sm);
  }
`;
