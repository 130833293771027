import { useMemo } from 'react';
import { faVideo, faWaveformLines } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import cardBackground from '@/assets/card-bg-texture.svg';
import spinnerAnimationData from '@/assets/spinner.lottie.json';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import {
  Card,
  CardContent,
  CardImage,
  CardImagePlaceholder,
  CardImageWrapper,
  CardText,
  Duration,
  IconWrapper,
  SpinnerAnimation,
} from './StoriesGridCard.styles';

export type StoriesGridCardAction = 'click';

interface StoriesGridCard {
  id: string;
  title: string;
  contentPhotoUrl?: string | null;
  processing?: boolean;
  duration?: number | null;
  type?: 'video' | 'audio';
  onAction: (id: string, action: StoriesGridCardAction, processing: boolean) => void;
}

const opacityAnimation = {
  rest: { opacity: 0, ease: 'easeOut', duration: 0.2, type: 'tween' },
  hover: {
    opacity: 1,
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

const durationWrapperAnimation = {
  rest: { maxWidth: '1.8rem' },
  hover: {
    maxWidth: '10rem',
    transition: {
      duration: 0.2,
      type: 'tween',
      ease: 'easeIn',
    },
  },
};

export function StoriesGridCard({
  id,
  title,
  contentPhotoUrl,
  processing = false,
  duration,
  type,
  onAction,
}: StoriesGridCard) {
  const mobile = useIsMobileViewport();

  const formattedDuration = useMemo(() => {
    if (processing) {
      return 'Processing...';
    }
    const date = new Date((duration || 0) * 1000);
    const formatter = new Intl.DateTimeFormat('en', { minute: '2-digit', second: '2-digit' });
    return formatter.format(date);
  }, [duration, processing]);

  return (
    <Card
      onClick={() => onAction(id, 'click', processing)}
      initial="rest"
      whileHover={mobile ? undefined : 'hover'}
      animate="rest"
    >
      <CardImageWrapper>
        {contentPhotoUrl ? (
          <CardImage src={contentPhotoUrl} alt="Stories photo" variant="light" />
        ) : (
          <CardImagePlaceholder src={cardBackground} alt="Stories photo" variant="light" />
        )}
      </CardImageWrapper>

      <IconWrapper variants={durationWrapperAnimation}>
        {processing === true ? (
          <SpinnerAnimation animationData={spinnerAnimationData} loop />
        ) : (
          <FontAwesomeIcon
            color={ColorsTokens['on-surface-tertiary']}
            size="sm"
            icon={type === 'video' ? faVideo : faWaveformLines}
          />
        )}

        <Duration variants={opacityAnimation}>
          <RMText type="sans" size="xxs">
            {formattedDuration}
          </RMText>
        </Duration>
      </IconWrapper>

      <CardContent data-has-image={!!contentPhotoUrl} variants={opacityAnimation}>
        <CardText
          align={mobile ? 'left' : 'center'}
          type="sans"
          size={mobile ? 'xs' : 's'}
          lineHeight={mobile ? 'xs' : 's'}
          bold
          color={contentPhotoUrl ? 'inverse-on-surface-primary' : 'on-surface-primary'}
        >
          {title}
        </CardText>
      </CardContent>
    </Card>
  );
}
