import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faEllipsis, faRotateRight, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { AssetAlternativeMetadataType, BaseAssetAlternativeType } from '@remento/types/alternative';
import { AssetType } from '@remento/types/asset';
import { PromptType } from '@remento/types/project';
import { Story, StoryStatus } from '@remento/types/story';
import { getStoryTitle } from '@remento/utils/entity/story';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMContextMenu } from '@/components/RMContextMenu';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMLoader } from '@/components/RMLoader/RMLoader';
import { toast } from '@/components/RMToast/RMToast';
import { getProjectPromptsPath } from '@/modules/routing/index.ts';
import { useServices } from '@/Services';
import {
  useAlternativeFileUrl,
  useAlternativeType,
  useAssetAlternativesQuery,
  useAssetQuery,
} from '@/services/api/asset';
import { CreatePromptPayload, useProjectQuery } from '@/services/api/project';
import { useFilterStoriesIds, usePollStoriesIds, useProjectStoriesQuery, useStoryQuery } from '@/services/api/story';
import { captureException } from '@/utils/captureException';
import { getActiveRoute } from '@/utils/getActiveRoute';
import { secureUuid } from '@/utils/uuid';

import { StoriesGrid, StoriesGridCardAction } from '../components/StoriesGrid';
import { createStoriesManager, getActiveStoryId, setActiveStoryId, setStoriesIds } from '../states/stories.manager';
import { VideoManagerProvider } from '../states/video.manager';

import { StoryDialogContainer } from './StoryDialog.container';

export interface StoriesItemContainerProps {
  visualization: 'grid';
  storyId: string;
  onAction: (id: string, action: StoriesGridCardAction, processing: boolean) => void;
}

export function StoriesItemContainer({ storyId, onAction }: StoriesItemContainerProps) {
  const storyQuery = useStoryQuery(storyId);
  const alternativesQuery = useAssetAlternativesQuery(storyQuery.data?.imageAssetIds[0] ?? null);
  const imageUrl = useAlternativeFileUrl(alternativesQuery.data?.[0] ?? null);
  const recordingQuery = useAssetQuery(storyQuery.data?.recordingsIds[0]);
  const title = getStoryTitle(storyQuery.data) ?? storyQuery.data?.metadata.title ?? '';
  const recordingAlternativeQuery = useAssetAlternativesQuery(storyQuery.data?.recordingsIds[0] ?? null);
  const recordingAlternative = useAlternativeType(
    recordingAlternativeQuery.data,
    BaseAssetAlternativeType.ORIGINAL,
    AssetAlternativeMetadataType.VIDEO,
  );

  let duration = 0;
  if (recordingAlternative?.metadata?.type === AssetAlternativeMetadataType.VIDEO) {
    duration = recordingAlternative.metadata.duration;
  }

  if (!storyQuery.data) {
    // TODO - Add skeleton
    return null;
  }

  return (
    <StoriesGrid.Card
      id={storyId}
      title={title}
      processing={storyQuery.data.status === StoryStatus.PROCESSING}
      duration={duration}
      contentPhotoUrl={imageUrl}
      type={recordingQuery.data?.type === AssetType.AUDIO_RECORDING ? 'audio' : 'video'}
      onAction={onAction}
    />
  );
}

export interface StoriesContainerProps {
  visualization: 'grid';
  projectId: string;
}

export function StoriesContainer({ visualization, projectId }: StoriesContainerProps) {
  const navigate = useNavigate();
  const params = useParams();
  const {
    storyViewerAnalyticsService,
    storyService,
    storyCacheService,
    projectCacheService,
    projectService,
    entityCacheManagerService,
  } = useServices();

  // Processing story
  const [processingStory, setProcessingStory] = useState<Story | null>(null);
  const [removeProcessingConfirmationOpen, setRemoveProcessingConfirmationOpen] = useState(false);
  const [reRecordConfirmationOpen, setReRecordConfirmationOpen] = useState(false);

  const projectQuery = useProjectQuery(projectId);
  const hasTopics = (projectQuery.data?.configuration.topics.length ?? 0) > 0;

  const storiesQuery = useProjectStoriesQuery(projectId);
  const recordedStoriesIds = useFilterStoriesIds(storiesQuery.data ?? null, StoryStatus.RECORDED);
  const processingStoriesIds = useFilterStoriesIds(storiesQuery.data ?? null, StoryStatus.PROCESSING);
  usePollStoriesIds(processingStoriesIds, StoryStatus.RECORDED);

  const storiesManager = useMemo(() => {
    const defaultStoryId = getActiveRoute()?.params.storyId ?? null;
    return createStoriesManager([], projectId, defaultStoryId, navigate);
  }, [projectId, navigate]);

  useEffect(() => {
    setStoriesIds(storiesManager, recordedStoriesIds ?? []);
  }, [recordedStoriesIds, storiesManager]);

  // Deals with browser history (back and forward)
  useEffect(() => {
    if (!params.storyId) {
      return;
    }

    const activeStoryId = getActiveStoryId(storiesManager);
    if (activeStoryId === params.storyId) {
      return;
    }

    setActiveStoryId(storiesManager, params.storyId);
  }, [params.storyId, storiesManager]);

  const handleAction = useCallback(
    async (id: string, action: StoriesGridCardAction, processing: boolean) => {
      if (processing) {
        const story = await storyCacheService.getStory(id);
        setProcessingStory(story);
        return;
      }

      switch (action) {
        case 'click': {
          setActiveStoryId(storiesManager, id);
          storyViewerAnalyticsService.onStoriesOpened();
          break;
        }
        default: {
          console.warn(`Action ${action} performed on ${id} not implemented yet`);
          break;
        }
      }
    },
    [storiesManager, storyViewerAnalyticsService, storyCacheService],
  );

  const handleAddPrompts = useCallback(() => {
    navigate(getProjectPromptsPath(projectId), {
      state: {
        openPromptPicker: true,
      },
    });
  }, [projectId, navigate]);

  const handleProcessingDialogConfirm = () => {
    setProcessingStory(null);
  };

  const handleProcessingDialogRemove = () => {
    setRemoveProcessingConfirmationOpen(true);
  };

  const handleRemoveProcessingStory = useCallback(async () => {
    if (processingStory == null) return;

    try {
      const deleteMutation = storyService.createDeleteStoryMutation(processingStory);
      await storyCacheService.deleteStory(processingStory.id, deleteMutation);

      setActiveStoryId(storiesManager, null);
      setRemoveProcessingConfirmationOpen(false);
      setProcessingStory(null);
    } catch (error) {
      captureException(error, true);
      toast('Failed to remove the story', 'root-toast', 'error');
    }
  }, [processingStory, storyService, storyCacheService, storiesManager]);

  const handleCancelProcessingRemove = useCallback(() => {
    setRemoveProcessingConfirmationOpen(false);
  }, []);

  const handleAskReRecordStory = useCallback(() => {
    setReRecordConfirmationOpen(true);
    storyViewerAnalyticsService.onStoriesActionPerformed('re-record');
  }, [storyViewerAnalyticsService]);

  const handleCancelReRecord = useCallback(() => {
    setReRecordConfirmationOpen(false);
  }, []);

  const handleReRecordStory = useCallback(async () => {
    if (!processingStory) return null;

    try {
      const prompt = await projectCacheService.getPrompt(processingStory.promptId);
      const [promptQuestionId] = await projectCacheService.getPromptQuestions(processingStory.promptId);
      const promptQuestion = await projectCacheService.getQuestion(promptQuestionId);

      if (prompt === null) throw new Error('Prompt not found');
      if (promptQuestion === null) throw new Error('Prompt question not found');

      const newPromptPayload: CreatePromptPayload =
        prompt?.type === PromptType.PHOTO
          ? {
              id: secureUuid(),
              type: PromptType.PHOTO,
              status: null,
              imagesIds: prompt.imagesIds,
              question: promptQuestion.text ?? '',
            }
          : {
              id: secureUuid(),
              type: PromptType.TEXT,
              status: null,
              question: promptQuestion.text ?? '',
              template: prompt.template,
            };

      const [newPrompt] = await projectCacheService.createPrompts(prompt.projectId, [newPromptPayload]);

      // Run mutations
      await entityCacheManagerService.mutate(projectService.createSetSentMutation(newPrompt));

      navigate(getProjectPromptsPath(projectId));
    } catch (error) {
      captureException(error, true);
      toast('Failed to re-record the story', 'root-toast', 'error');
    }
  }, [processingStory, projectCacheService, entityCacheManagerService, projectService, navigate, projectId]);

  if (!storiesQuery.data) {
    return <RMLoader center={true} />;
  }

  if (storiesQuery.data.length === 0) {
    return <StoriesGrid.Empty hasTopics={hasTopics} onAddPrompts={handleAddPrompts} />;
  }

  return (
    <>
      <StoriesGrid.Root>
        {storiesQuery.data.map((storyId) => (
          <StoriesItemContainer key={storyId} visualization={visualization} storyId={storyId} onAction={handleAction} />
        ))}

        <StoriesGrid.NewPromptCard hasTopics={hasTopics} onClick={handleAddPrompts} />
      </StoriesGrid.Root>

      <VideoManagerProvider>
        <StoryDialogContainer storiesManager={storiesManager} />
      </VideoManagerProvider>

      <RMConfirmationModal
        open={processingStory !== null && !removeProcessingConfirmationOpen && !reRecordConfirmationOpen}
        title="Story processing..."
        message="Check back in a minute or two. Have an issue? Contact us at support@remento.co"
        cancelLabel={null}
        confirmLabel="OK"
        rightAdornment={
          <RMContextMenu.Root
            align="end"
            Trigger={<RMIconButton icon={faEllipsis} size="xl" tooltip={null} backgroundColor="transparent" as="div" />}
          >
            <RMContextMenu.Item
              label="Re-record story"
              value="re-record"
              rightIcon={faRotateRight}
              onClick={handleAskReRecordStory}
            />
            <RMContextMenu.Item
              label="Delete story"
              value="delete-story"
              rightIcon={faTrashCan}
              destructive
              onClick={handleProcessingDialogRemove}
            />
          </RMContextMenu.Root>
        }
        onConfirm={handleProcessingDialogConfirm}
        onClose={handleProcessingDialogConfirm}
      />

      <RMConfirmationModal
        open={removeProcessingConfirmationOpen}
        title="Remove story?"
        message="This action cannot be undone."
        type="danger"
        confirmLabel="Remove"
        onConfirm={handleRemoveProcessingStory}
        onCancel={handleCancelProcessingRemove}
        onClose={handleCancelProcessingRemove}
      />

      <RMConfirmationModal
        open={reRecordConfirmationOpen}
        title="Re-record story?"
        message="This action will create and send an identical prompt right now. You’ll be able to delete either at any time"
        confirmLabel="Re-send prompt"
        onConfirm={handleReRecordStory}
        onCancel={handleCancelReRecord}
        onClose={handleCancelReRecord}
      />
    </>
  );
}
