import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMImage } from '@/components/RMImage/RMImage';
import { RMText } from '@/components/RMText/RMText';

export const Card = styled(motion.div)`
  padding: var(--spacing-xs);
  background-color: var(--surface-brightest);
  box-shadow: var(--elevation-bottom-2);
  border-radius: var(--radius-round);
  border: 1px solid var(--inverse-border-hairline);
  cursor: pointer;
  transition: all 200ms ease-out;
  position: relative;

  width: 100%;
  height: 17.5rem;

  body[data-mobile='true'] & {
    padding: var(--spacing-2xs);
    height: 15rem;
  }

  body[data-mobile='false'] & {
    :hover {
      box-shadow: var(--elevation-bottom-4);
      transform: scale(1.01);
    }
  }

  :active {
    opacity: 0.9;
  }
`;

export const CardImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: var(--radius-round);
  background: var(--surface-dim);
`;

export const CardImage = styled(RMImage)`
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  border-radius: var(--radius-regular);
  aspect-ratio: 1.3;
  height: 100%;
  width: 100%;
`;

export const CardImagePlaceholder = styled(RMImage)`
  object-fit: cover;
  object-position: right;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  border-radius: var(--radius-regular);

  body[data-mobile='true'] &,
  body[data-tablet='true'] & {
    object-fit: contain;
  }
`;

export const IconWrapper = styled(motion.div)`
  max-width: 1.8rem;
  height: 1.5rem;
  position: absolute;
  left: var(--spacing-xl);
  bottom: var(--spacing-xl);
  background: var(--surface-brightest);
  border-radius: var(--radius-full);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-family: 'RobotoMono';
  font-size: 0.75rem;
  padding: var(--spacing-xs);
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--on-surface-tertiary);

  body[data-mobile='true'] & {
    bottom: unset;
    top: var(--spacing-sm);
    left: var(--spacing-sm);
  }
`;

export const Duration = styled(motion.span)`
  margin-left: var(--spacing-xs);
  color: var(--on-surface-tertiary);
`;

export const CardContent = styled(motion.div)`
  position: absolute;
  inset: var(--spacing-xs);
  padding: 0 var(--spacing-3xl);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius-regular);

  &[data-has-image='false'] {
    background: none;

    // to prevent framer motion from hide element in this state
    opacity: 1 !important;
  }

  body[data-mobile='true'] & {
    inset: var(--spacing-2xs);
  }

  body[data-mobile='true'] &[data-has-image='true'] {
    opacity: 1 !important;
    background: linear-gradient(180deg, rgba(30, 47, 47, 0) 10%, rgba(30, 47, 47, 0.8) 90.8%);
  }

  body[data-mobile='false'] &[data-has-image='true'] {
    background: rgba(30, 47, 47, 0.6);
  }
`;

const maxLines = 3;

export const CardText = styled(RMText)`
  --line-height: 1.5rem;

  line-height: var(--line-height);
  max-height: calc(var(--line-height) * ${maxLines});
  display: -webkit-box;
  -webkit-line-clamp: ${maxLines};
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  overflow: hidden;

  body[data-mobile='true'] & {
    --line-height: 1.225rem;
    width: calc(100% - var(--spacing-xs) * 2);
    position: absolute;
    left: var(--spacing-xs);
    bottom: var(--spacing-sm);
  }
`;

export const CardMetadata = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-sm);

  svg {
    color: var(--on-surface-tertiary);
  }
`;

export const SpinnerAnimation = styled(LottieAnimation)`
  position: relative;
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
`;
