import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { Card } from './StoriesGridNewPromptCard.styles';

interface StoriesGridNewPromptCardProps {
  hasTopics: boolean;
  onClick?: () => void;
}

export function StoriesGridNewPromptCard({ hasTopics, onClick }: StoriesGridNewPromptCardProps) {
  const mobile = useIsMobileViewport();

  return (
    <Card onClick={onClick}>
      <FontAwesomeIcon fontWeight={700} color={ColorsTokens.primary} size={mobile ? 'lg' : 'sm'} icon={faPlus} />
      <RMText align="center" type="sans" bold size={mobile ? 's' : 'm'} color="primary">
        Add {hasTopics ? 'prompts' : 'photos'}
      </RMText>
    </Card>
  );
}
