import { styled } from '@linaria/react';

export const Card = styled.div`
  width: 100%;
  height: 17.5rem;
  background-color: var(--surface);
  border-radius: var(--radius-round);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-sm);
  transition: all 200ms ease-out;
  border: 2px dashed var(--border-weak);
  cursor: pointer;

  body[data-mobile='false'] & {
    :hover {
      background-color: var(--darken-neutral-hover);
    }

    :active {
      background-color: var(--darken-neutral-press);
    }
  }

  body[data-mobile='true'] & {
    height: 15rem;
    flex-direction: column;
  }
`;
