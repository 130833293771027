import { faAdd } from '@fortawesome/pro-solid-svg-icons';

import StoriesEmptySrc from '@/assets/stories-empty.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMText } from '@/components/RMText/RMText';

import { Container, Image } from './StoriesGridEmpty.styles';

interface StoriesGridEmptyProps {
  hasTopics: boolean;
  onAddPrompts: () => void;
}

export function StoriesGridEmpty({ hasTopics, onAddPrompts }: StoriesGridEmptyProps) {
  return (
    <Container>
      <Image src={StoriesEmptySrc} alt="Prompt ghost icon" />
      <RMText align="center" type="sans" size="s" color="on-surface-tertiary">
        Recorded stories <br /> will appear here.
      </RMText>
      <RMButton background="outlined" leftIcon={faAdd} onClick={onAddPrompts}>
        Add {hasTopics ? 'prompts' : 'photos'}
      </RMButton>
    </Container>
  );
}
