import { StoriesGridCard } from './StoriesGridCard';
import { StoriesGridEmpty } from './StoriesGridEmpty';
import { StoriesGridNewPromptCard } from './StoriesGridNewPromptCard';
import { StoriesGridRoot } from './StoriesGridRoot';

export const StoriesGrid = {
  Empty: StoriesGridEmpty,
  Root: StoriesGridRoot,
  Card: StoriesGridCard,
  NewPromptCard: StoriesGridNewPromptCard,
};

export type { StoriesGridCardAction } from './StoriesGridCard';
